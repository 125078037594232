@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;1,100&display=swap');

.rbc-header {
  border: 0px !important;
  padding: 8px 1px !important;
}

.rbc-day-bg, .rbc-time-header-content, .rbc-time-header {
  border: 0px !important;
}

.rbc-header a {
  text-decoration: none !important;
}

.rbc-time-view {
  border-radius: 4px !important;
  padding-left: 2px !important;
  padding-right: 2px !important;
  padding-bottom: 2px !important;
}

.rbc-row {
  min-height: 0px !important;
}

.rbc-timeslot-group { 
  min-height: 55px !important;
}

.rbc-allday-cell {
  display: none;
}

.rbc-time-content {
  cursor: pointer;
}

.rbc-event-label {
  display: none !important;
}

.rbc-event:hover {
  transition: all 0.2s ease-out !important;
  box-shadow: 0px 5px 10px rgba(38, 38, 38, 0.2) !important;
}

.navbar-nav {
  text-align: right;
}

.MuiButton-label {
  text-transform: none;
  font-size: 16px;
}